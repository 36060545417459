@import 'assets/scss/variables';

.sidebar-contacts-filters {
  position: relative;

  &__main-filters-wrap {
    display: flex;
    flex-direction: column;
    gap: 19px;
    margin-bottom: 24px;
  }

  &__filters-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 4px;

    &:has(.sidebar-contacts-filters__main-filter-counter):not(:last-child) {
      margin-bottom: -10px;
    }

    &--operators {
      margin-bottom: -29px;
    }
  }

  &__main-filter {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 22px;
    border-radius: 6px;
    font-size: 12px;
    text-transform: capitalize;
    color: $color-text-primary;
    border: 1.5px solid $color-mystic;
    background-color: $color-white;
    box-shadow: inset 4px -6px $color-mystic;
    cursor: pointer;
    overflow: hidden;

    &--operator {
      margin-bottom: 19px;
    }

    &>span {
      background-color: $color-mystic;
    }

    &--prebook svg {
      height: 14px;
      color: $color-persian-pink;
    }

    &-content {
      display: flex;
      align-items: center;
      position: relative;
      z-index: 2;
      padding: 0 9px;
      height: 20px;
      border-radius: 0px 0px 4px 4px;
      line-height: 19px;

      &:not(:only-child) {
        height: 20.5px;
      }
    }

    &-counters {
      display: flex;
      align-items: flex-end;
      position: relative;
      width: 100%;
      height: 10px;
    }

    &-counter {
      font-size: 10px;
      font-weight: bold;
      height: 13px;
      padding-top: 3px;
      width: 100%;
      color: $color-black;
      text-align: center;
      line-height: 10px;

      &--all {
        background-color: $color-white;
      }

      &--unread {
        background-color: $color-froly;
      }
    }

    &--active {
      color: $color-white;
      border-color: $color-bondi-blue;
      box-shadow: inset 4px -6px $color-bondi-blue;

      &>span {
        background-color: $color-bondi-blue;
      }

      & svg {
        align-self: center;
        color: $color-white;
      }
    }
  }

  &__input-wrap {
    position: relative;
    margin-bottom: 2px;
    width: 100%;
    height: 28px;

    .search-input {
      &__icon--clear {
        width: 6px;
        height: 6px;
        color: #FF3D2B;
      }
    }
  }

  &__input {
    width: 100%;
    height: 100%;
  }

  &__search-input {
    padding-right: 70px !important;
  }

  &__toogle-btn-container {
    position: relative;
    height: calc(100% - 2px);
  }

  &__toggle-btn {
    position: absolute;
    right: 38px;
    // top: 8px;
  }

  &__toggle-btn-icon-wrap {
    position: absolute;
    right: 12px;
    top: 2px;
    background-color: $color-mystic;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    color: #fff;
    font-size: 12px;

    &--active {
      top: 0px;
      right: 0px;
      background-color: $color-bondi-blue;
    }
  }

  &__toggle-btn-icon {
    height: 20px;
    width: 20px;
  }
}

.sidebar-filter-select {
  display: flex;
  align-items: center;
  margin-bottom: 2px;

  .react-select {
    width: 100%;

    &__control {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      padding-left: 0;
      height: 28px;
      border-radius: 6px;

      &--menu-is-open {
        background-color: $color-athens-gray;
      }
    }

    &__menu {
      min-width: 200px;
      max-width: 240px;
      left: 50%;
      transform: translateX(-50%);

      &-list {
        max-height: 100%;
      }
    }

    &__single-value {
      font-weight: bold;
    }

    &__indicator {
      padding: 0 10px;
    }
  }
}
