.booking-details {
  overflow: hidden;
  text-overflow: ellipsis;
  
  &--column {
    display: flex;
    flex-direction: column;
  }

  :is(&--row, .tooltip) {
    & > span:not(:first-child)::before {
      content: '/';
      margin: 0 4px;
    }
  }
}
