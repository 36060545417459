.booking-requirements-new {
  display: flex;
  flex-direction: row;
  height: 10px;
  gap: 4px;
  width: fit-content;
  max-width: 100%;

  &__item {
		display: block;
    width: 3px;
    height: 100%;
    border-radius: 2px;
  }

  &__titles {
    display: flex;
    flex-direction: column;

    &-item {
      list-style: none;
      text-align: left;
    }
  }
}
