@import 'assets/scss/variables';

.contacts__list {
  &--empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 13px;
    color: #bfbfbf;
  }
}

.contact-item {
  display: flex;
  position: relative;
  left: 19px;
  width: calc(100% - 20px);
  height: 40px !important;
  border: 1px solid $color-athens-gray;
  background-color: $color-athens-gray;
  border-radius: 6px;
  padding: 3px 7px 3px 5px;
  outline: none;
  transition-property: border-color, box-shadow, height;
  transition-duration: 0.1s;
  transition-timing-function: ease-in;

  &__tag {
    margin-right: 4px;
  }

  &__fn-tooltip-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    z-index: 100;
    box-shadow: $shadow-primary;
    justify-content: center;
    text-align: center;
    background: $color-white;
    border-radius: 6px;
    font-size: 13px;
    max-width: 240px;
    width: 100%;
    min-height: 38px;
    color: $color-gray;
    padding: 10px;

    & > div {
      position: relative;
      max-width: 240px;
      width: 100%;
      min-height: 38px;
    }
  }

  &__wrapper:first-child {
    margin-top: 3px;
  }

  &__separator-line {
    display: none;
  }

  &--divided {
    .contact-item__dividing-line {
      position: absolute;
      display: block;
      bottom: -7px;
      left: 50%;
      transform: translateX(-50%);
      height: 2px;
      width: 90%;
      border-radius: 8px;
      content: "";
      background-color: $color-bondi-blue;
    }
  }

  &--unread-msgs {
    background-color: #fff;
    border-color: $color-mystic;
  }

  &--girl {
    background-color: $color-black-squeeze-light;
  }

  &.contact-item--active {
    border: 1px solid $color-bondi-blue;
    background-color: rgba($color-bermuda, 0.44);
  }

  &.contact-item--active-in-buffer {
    border: 1px solid $color-bondi-blue;
    background-color: #ccedee;
  }

  &:hover {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.12);
    cursor: pointer;
    border-color: transparent;

    & .contact-item__delete-buffer-btn {
      display: block;
    }
  }

  &--pending {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #cacaca;
  }

  &__rating {
    white-space: nowrap;

    svg {
      color: #f2cc0c;
    }
  }

  &__rates {
    margin-left: 5px;
  }

  &__room-control-wrap {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;

    .contact-item__count {
      position: sticky;
    }
  }

  &__delete-buffer {
    &-btn {
      display: none;
      z-index: 9;
      position: absolute;
      top: 0;
      right: 7px;
      bottom: 0;
      width: 12px;
      height: 12px;
      margin: auto;
      padding: 0;
    }

    &-icon {
      transform: rotate(45deg);
      width: 12px;
      height: 12px;
      color: $color-persimmon;
    }
  }

  &::after {
    box-sizing: border-box;
    display: inline-block;
    width: 7px;
    height: 7px;
    position: absolute;
    border-radius: 50%;
    top: 2px;
    left: 1px;
    transition: all 0.2s ease-in;
  }

  &--available {
    &::after {
      content: '';
      background-color: rgb(1, 223, 133);
    }
  }

  &--off {
    &::after {
      content: '';
      background-color: #f8737f;
    }
  }

  &--off-today {
    &::after {
      content: '';
      background-color: #933131;
    }
  }

  &--open.contact-item--available::after {
    left: 15px;
  }

  &--with-agent {
    background-color: rgba(253, 5, 232, 0.15);
  }

  &__content-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  &__control-wrap {
    display: flex;
    align-items: center;

    .contact-item__count {
      position: sticky;
    }
  }

  //&__icon {
  //  font-size: 17px;
  //  margin-right: 5px;
  //}

  &__mode-table {
    position: absolute;
    background: #ffffff;
    width: 70%;
    border-radius: 4px;
    top: -2px;
    right: 0;
    border: 1px solid #808080;
    z-index: 10;

    .mode-table {
      &__header {
        width: 100%;
        height: 14px;
        display: flex;
        border: 1px solid #e8ebf1;
        border-radius: 5px 5px 0 0;
        font-size: 11px;
        align-items: center;

        &--item {
          width: 20%;
          font-weight: normal;
          text-align: center;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      &__item {
        height: 14px;
        display: flex;
        align-items: center;
        border: 1px solid #e8ebf1;
        border-top: none;
        font-size: 11px;

        &:last-child {
          border-radius: 0 0 5px 5px;
        }

        &--block {
          position: relative;
          width: 20%;
          text-align: center;
        }

        .name {
          text-transform: capitalize;
        }
      }
    }
  }

  &__mode {
    position: absolute;
    left: 4px;
    bottom: 0;
    font-size: 8px;
    font-weight: 600;
    color: #fff;
    background-color: #a05900;
    padding: 1px 3px;
    white-space: nowrap;
    border-radius: 2px;
  }

  &--focused {
    background-color: #f6f6f8;
  }

  &:hover &__btn--room-call {
    display: block;
  }

  &--open {
    background-color: #fff;
    padding: 4px 35px 4px 15px;
    border-radius: 10px;
    height: 83px !important;
    width: calc(100% - 14px) !important;
  }

  &--open &__description,
  &--open &__btn--edit {
    display: -webkit-box;
    position: absolute;
    left: 16px;
    bottom: 7px;

    animation: showRow 1s forwards;
  }

  &--open &__btn--edit {
    top: 5px;
    left: 2px;
  }

  &--open &__btn--edit,
  &--open &__btns {
    opacity: 1;
    transition: all 0.2s ease-in;
    z-index: 1;
  }

  &--open &__btns {
    right: 5px;
    top: 0;
  }

  &--open &__info:hover>.contact-item__tooltip {
    visibility: visible;
  }

  // &--open .contact-item__info {
  //   width: calc(100% - 28px);
  // }
  &__item {
    padding: 5px;
    cursor: pointer;
    position: relative;

    &--status {
      position: absolute;
      top: 18px;
      left: 2px;
      text-align: center;
      padding: 2px 4px;
      width: 50px;
      // display: block;
      border: 1px solid red;
      background: lightgreen;
      color: #155724;
      background-color: #d4edda;
      border-color: #c3e6cb;
      border-radius: 3px;
      font-size: 11px;
    }
  }

  &__img-wrap {
    width: 32px;
    height: 32px;
    position: relative;
    margin-right: 5px;
    min-width: 32px;
    border-radius: 50%;
    box-shadow: 0px 0 0px 1px #00bf0d85;

    &--micro {
      height: 10px;
      width: 10px;
      min-width: 10px;
      margin-right: 4px;
      // border: 1px solid #0092f2;
      // border-radius: 50%;
    }
  }

  &__telegram-icon,
  &__whatsapp-icon,
  &__iphone-icon,
  &__telegram-group-icon {
   position: absolute;
   right: 0;
   bottom: -3px;
   width: 16px;
   height: 16px;
   border: 1px solid #fff;
   border-radius: 50%;
   background: #fff;
   color: $color-azure-radiance;
  }

  // &__whatsapp-icon {
  //   color: $color-jade;
  // }
  // &__iphone-icon {
  //   color: $color-gray;
  // }

  &__booking-icon {
    font-size: 17px;
    margin-right: 5px;
  }

  &__icon-wrapper {
    position: absolute;
    color: $color-azure-radiance;
    right: -3px;
    bottom: -3px;
    border-radius: 50%;
    width: 17px;
    height: 17px;
    overflow: hidden;
    position: absolute;
    right: -2px;
    bottom: -3px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    &--top {
      top: -3px;
    }
  }

  &__icon {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #fff;
    border: 1px solid $color-white;

    &--telegram, &--robot {
      color: $color-azure-radiance;
    }

    &--robot {
      width: 15px;
    }

    &--whatsapp {
      color: $color-jade;
    }
    &--apple {
      color: $color-gray;
    }
    &--bot {
      color: $color-red;
    }

    &--mail {
      color: $color-bondi-blue;
      width: 12px;
      height: 12px;
      border-radius: 0;
    }
  }

  &__telegram-group-icon {
    background-color: $color-azure-radiance;
    color: $color-white;
    border: none;
    width: 16px;
    height: 16px;
  }

  &__status {
    width: 10px;
    height: 10px;
    position: absolute;
    border-radius: 50%;
    top: -3px;
    left: 0;
    background-color: red;
    z-index: 2;

    &::after {
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: calc(100% - 14px);
      top: -6px;
      z-index: 9999;
    }
  }

  &__pinned-icon {
    position: absolute;
    top: -5px;
    left: -5px;
    width: 12px;
    height: 12px;
    transition: all 0.2s ease-in;
    color: $color-bondi-blue;
    transform: rotate(-45deg);
  }

  &__tooltip {
    visibility: hidden;
    -width: 60px;
    padding: 5px;
    font-size: 12px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    position: absolute;
    bottom: 24px;
    left: 56px;
    z-index: 1;
    display: flex;
    align-items: center;
  }

  &--marked {
    &::before {
      content: '';
      position: absolute;
      bottom: 1px;
      left: 1px;
      width: 11px;
      height: 11px;
      background: $color-froly;
      border-bottom-left-radius: 5px;
      clip-path: polygon(100% 100%, 0% 0%, 0% 100%);
    }
  }

  &__img {
    height: 32px;
    border-radius: 50%;
  }

  &__info {
    color: #808080;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 1px;

    // .contact-item__list-item{
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-between;
    //   width: auto;
    //   font-size: 14px;
    //   height: 48px;
    // }
  }

  &__name {
    display: flex;
    flex-grow: 1;
    align-items: center;
    overflow: hidden;
    margin: 0;
    width: 70px;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 500;
  }

  &__icon-info {
    fill: '#BFBFBF';
    width: 11px;
    margin: 0 5px;
  }

  &__ex {
    color: #f27e3f;
    font-weight: bold;
  }

  &__time {
    color: #000;
    font-size: 10px;
    white-space: nowrap;
    margin-left: 5px;

    .contact-item--unread-msgs:not(.contact-item--open) & {
      margin-right: 15px;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    color: $color-gray;
    margin-bottom: 1px;
  }

  &__draft-icon {
    width: 11px;
    height: 11px;
    margin-right: 5px;
    color: $color-gray;
  }

  &__schedule-msg-icon {
    width: 10px;
    height: 10px;
    margin-left: 6px;
    color: $color-bondi-blue;
  }

  &__related {
    max-width: 116px;
    margin-left: 6px;
    color: $color-azure-radiance;
    font-size: 12px;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__edit-contact-btn {
    padding: 0;
    display: flex;
    color: $color-bondi-blue;
    margin-left: 6px;

    &-icon {
      height: 14px;
      width: 14px;
    }
  }

  &__last-msg {
    flex-grow: 1;
    width: 0;
    overflow: hidden;
    font-size: 12px;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;

    // &--operator {
    //   font-weight: 500;
    // }

    .interaction__text {
      line-height: unset;
      margin: 0;
      font-size: unset;
      white-space: nowrap;
      overflow: hidden;
      color: #808080;
      text-overflow: ellipsis;
    }

    &::-moz-selection {
      background: transparent;
    }

    &::selection {
      background: transparent;
    }
  }

  &__description {
    border-top: 1px solid #bfbfbf;
    padding-top: 5px;
    color: #bfbfbf;
    font-size: 13px;
    width: calc(100% - 55px);
    max-height: 34px;
    overflow: hidden;

    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__btns {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    position: absolute;
    z-index: -1;
    right: -30px;
    opacity: 0;
    transition: all 0.2s ease-in;
  }

  &__btn {
    // margin-bottom: 10px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    background: transparent;

    // &--call:disabled > svg {
    //   fill: grey;
    // }

    &--mess,
    &--call,
    &--edit,
    &--share,
    &--room-call,
    &--share-contact {
      background-color: none;
      border: none;
      padding: 0;
      outline: none;
    }

    &--room-call {
      display: none;
      margin-left: 4px;
    }

    &--edit {
      width: 12px;
      height: 12px;
      z-index: -1;
      opacity: 0;
      position: absolute;
      top: -20px;
      left: 5px;
      transition: all 0.2s ease-in;
      color: $color-gray;
    }

    &--copy {
      background-color: #fff;
      margin: 0 5px 0 0;
      padding: 0;
    }
  }

  &__count {
    position: absolute;
    top: -2px;
    right: -2px;
    display: inline-block;
    width: 18px;
    border-radius: 50%;
    background-color: $color-dodger-blue;
    text-align: center;
    line-height: 17px;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

    &--important {
      background-color: #f8737f;
    }

    &--related {
      background-color: #fff;
      color: #000;
    }
  }

  &__busy {
    position: absolute;
    top: 0px;
    left: 2px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #f8737f;
    z-index: 2;
    // width: 10px;
    // height: 10px;
    // border: 2px solid #fff;

    &::after {
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: calc(100% - 14px);
      top: -6px;
      z-index: 9999;
    }
  }

  &__sessions-indicator {
    position: absolute;
    top: 0px;
    right: 50px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-family: Roboto;
    font-style: italic;

    span {
      padding-top: 2px;
      padding-left: 2px;
    }
  }

  &__bookings-block {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 18px;
    height: 40px;
    padding: 2px;
    border-radius: 6px;
    background-color: $color-wild-sand;

    &[data-popper-reference-hidden='true'],
    &--hidden {
      visibility: hidden;
      pointer-events: none;
    }
  }

  &__bookings-count {
    font-size: 12px;
  }

  &__unfinished-bookings-count {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    width: 16px;
    font-size: 12px;
    font-weight: 600;
    color: $color-black;
    border: 1px solid $color-black;
    border-radius: 3px;
    margin-right: 5px;
  }

  &__booking-icon-eye {
    width: 15px;
    height: 10px;
    margin-top: 6px;
    fill: $color-red-orange;
  }

  &--compare-clients-tels-spinner {
    z-index: 999;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@keyframes showRow {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes available-pulse {
  0% {
    box-shadow: 0 0 0 0px rgb(4, 241, 83);
  }

  100% {
    box-shadow: 0 0 0 15px rgba(4, 241, 83, 0);
  }
}
