@import 'assets/scss/variables';

.profile-card-photos {
  padding: 0 15px;
  background-color: $color-mystic;

  &__image-inner {
    position: relative;
    height: 100%;
    width: 100%;
    pointer-events: none;
    width: 0;
    height: 0;
    opacity: 0;
    transition: opacity 0.5s;

    &--active {
      pointer-events: all;
      width: 100%;
      height: 100%;
      opacity: 1;
    }
  }
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    position: absolute;
    top: 0px;
    left: 0px;
    transition: opacity 0.5s;
    cursor: pointer;
  }
  .lazy-load__error {
    width: 130px;
    height: 130px;
    object-fit: cover;
    position: static;
    cursor: pointer;
  }
  &__image-wrap {
    position: relative;
    // margin: 0 15px;
    // max-width: 238px;
    height: 328px;
    overflow: hidden;

    &:hover .profile-dropdown {
      display: block;
    }
    &:hover .booked-dropdown {
      height: 30px;
      opacity: 1;
    }

    &--available {
      &::after {
        content: "";
        box-sizing: border-box;
        display: inline-block;
        width: 15px;
        height: 15px;
        position: absolute;
        border-radius: 50%;
        top: -2px;
        left: 0;
        border: 2px solid #e6ebf1;
        background-color: #01df85;
        transition: all 0.2s ease-in;
      }
    }
    &--archived {
      &::after {
        content: "Archived";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        color: #f8737f;
        font-weight: 500;
      }

      .profile-card__image {
        filter: blur(10px);
      }

      &:hover {
        &::after {
          display: none;
        }
        .profile-card__image {
          filter: none;
        }
      }
    }
  }
  &__controller--next,
  &__controller--prev {
    position: absolute;
    z-index: 1;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 18px;
    font-size: 30px;
    color: $color-gray;
    cursor: pointer;

    &:hover {
      background-color: rgba(232, 236, 241, 0.2);
    }
    svg {
      pointer-events: none;
    }
  }
  &__controller--prev {
    left: 0;
  }
  &__controller--next {
    right: 0;
  }
  &__controller-icon {
    width: 26px;
    height: 26px;
  
    &--prev {
      transform: rotate(90deg);
    }
    
    &--next {
      transform: rotate(-90deg);
    }
  }

  &__desc {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin: 0;
    padding: 8px 15px;
    border-radius: 0 0 6px 6px;
    background-color: #fff;
    font-size: 12px;
    color: $color-azure-radiance;
  }
  &__profile-gallery {
    font-size: 12px;
    margin: 0;
    padding: 0;
    color: $color-azure-radiance;
  }
}
