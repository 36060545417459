@import 'assets/scss/variables';

.time-filter {
  display: flex;
  gap: 30px;
  
  &__item {
    color: $color-bondi-blue;
    font-size: 14px;
    line-height: 19px;
    font-family: Roboto;
    text-decoration: underline;
    text-transform: uppercase;
    letter-spacing: 0;
    cursor: pointer;
  }
}
