@import 'assets/scss/variables';

.header-modal-btns {
  display: flex;

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    padding: 0;
    width: 26px;
    height: 26px;

    &:last-child {
      margin-right: 0;
    }

    &--active {
      fill: $color-bermuda; 
    }
  }

  &__icon {
    width: 20px;
    height: 20px;
    color: $color-tundora;

    &--active {
      fill: $color-bondi-blue;
    }
  }

  &__bookings-icon {
    position: relative;

    &:before {
      content: 'B';
      display: block;
      width: 20px;
      min-width: 20px;
      height: 20px;
      border: 1px solid $color-tundora;
      border-radius: 4px;
      font-size: 17px;
      font-weight: 700;
    }
  }
}
