@import 'assets/scss/variables';
@import 'assets/scss/functions.scss';

.booking-status-filters {
  display: flex;
  align-items: center;
  background-color: $color-white;
  border: 1px solid $color-mystic;
  border-radius: 6px;
  overflow: hidden;
  width: fit-content;

  &--dark {
    background-color: $color-mystic;
    
    .booking-status-filters__item--active {
      background-color: $color-white;
    }
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 9px 8px 9px 0;
    letter-spacing: 1px;
    font-size: 12px;
    height: 100%;
    cursor: pointer;
    text-transform: capitalize;
    white-space: pre;
    color: $color-black;

    &:disabled {
      cursor: not-allowed;
    }

    .bookings-by-date__dot {
      margin-left: 5px;
    }

    &--active {
      background-color: rgba(136, 214, 217, 0.44);
    }

    &:not(:last-child) {
      &::after {
        position: absolute;
        right: 0;
        content: '';
        background-color: $color-manatee;
        width: 1px;
        height: 16px;
        opacity: 0.3;
      }
    }
  }

  &__dot {
    width: 6px;
    height: 6px;
    margin-left: 5px;
    border-radius: 50%;
  }

  &__label {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__counter {
    min-width: 36px;
    text-align: center;
  }
}
