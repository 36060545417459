@import 'assets/scss/variables';

.mail-chat-item {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  &--last .mail-chat-item__header,
  &--last .mail-chat-item__avatar-wrap {
    cursor: default;
  }

  &__main {
    display: flex;
    flex-direction: column;
  }

  &__avatar-wrap {
    display: flex;
    align-items: center;
    padding-right: 5px;
    cursor: pointer;
  }

  &__avatar {
    width: 32px;
    height: 32px;
    min-width: 32px;
    border: 1px solid darken(#e6ebf1, 10);
    border-radius: 50%;
    overflow: hidden;
    background-color: $color-white;
  }

  &__header {
    z-index: 2;
    position: sticky;
    top: 0;
    display: flex;
    flex-grow: 1;
    overflow: hidden;
    cursor: pointer;
    background-color: $color-white;
    padding-bottom: 5px;

    &-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-grow: 1;
    }

    &-top {
      display: flex;
      justify-content: space-between;
      flex-grow: 1;
      margin-right: 30px;
    }
  }

  &__info {
    display: flex;
    flex-direction: row;

    &-icon {
      display: flex;
      align-items: center;
    }
  }

  &__name {
    margin-right: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; 
    -webkit-box-orient: vertical;

    &-box {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      color: $color-gray;
      font-style: italic;
    }
  }

  &__email {
    margin-right: 15px;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; 
    -webkit-box-orient: vertical;
  }
  
  &__date {
    width: 135px;
    color: $color-gray;
    font-size: 12px;
    white-space: nowrap;
    text-align: end;
  }

  &__actions {
    display: inline-flex;
    align-items: center;
  }

  &__action-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    padding: 0;
    color: $color-gray;
    font-size: 18px;

    & svg {
      width: 20px;
      height: 20px;
    }

    &:disabled {
      cursor: default;
      > svg {
        opacity: .3;
      }
    }

    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  &__message {
    padding: 24px 0 36px 30px;
    position: relative;
    border-bottom: 1px solid rgba(#000, 0.15);
  }

  &__message-body-wrap {
    position: relative;
  }

  &__message-body {
    padding-bottom: 30px;
    border-bottom: 1px dashed $color-mystic;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
    color: #000000;
  }

  &__attachments {
    border-top: 1px solid rgba(#000, 0.12);
  }

  &__attachments-list {
    display: flex;
    flex-wrap: wrap;
  }

}

.mail-chat-item-reply {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding: 10px;
  margin-bottom: 25px;
  border: 1px solid $color-silver;
  border-radius: 6px;

  &::before {
    position: absolute;
    top: -20px;
    left: 0;
    font-size: 12px;
    letter-spacing: 1px;
    color: $color-tundora-light;
  }

  &--is-reply::before {
    content: "Reply";
  }

  &--is-forward::before {
    content: "Forward";
  }

  &__header {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    .form-text-field {
      width: 100%;
      position: relative;
    }
  }

  &__block {
    margin-top: 20px;
  }

  &__mail-info {
    display: flex;
    flex-direction: column;
  }

  &__mail-text {
    font-style: 14px;
  }

  &__reply-icon-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 15px;
    color: $color-gray;

    svg {
      width: 20px;
      height: 20px;
      min-width: 20px;
    }
  }

  &__main {
    position: relative;
    width: 100%;
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__email-group {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
  }

  &__email-label {
    margin-right: 12px;

    color: $color-gray;
    font-size: 16px;
    font-family: Roboto;
    line-height: 1;
  }

  &__email-input-wrap {
    position: relative;
    flex-grow: 1;
  }

  &__email-input {
    width: 100%;
    padding: 0;

    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    outline: none;

    color: #404040;
    line-height: 1.25;
  }

  &__attachment-list {
    margin: -2px -2px 14px;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }

  &__attachment-list-item {
    margin: 2px;
  }

  &__textarea-wrap {
    margin-bottom: 16px;

    .input-error-text {
      left: 0;
      bottom: -15px;
    }
  }

  &__textarea {
    display: block;
    width: 100%;
    border: none;
    outline: none;

    & .input {
      border: none;
      color: $color-black;
      resize: none;
    }

    & .form-textarea__input {
      width: 100%;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__send-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
    padding: 8px 25px;
    border-radius: 6px;
    color: $color-white;
    font: 14px Roboto;
    font-size: 14px;
    letter-spacing: 0.88px;
    background-color: $color-bondi-blue;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.12);
    transition: background-color linear .1s;

    &:hover {
      background-color: lighten($color-bondi-blue, 4);
    }

    &:disabled {
      cursor: not-allowed;
      opacity: .5;

      &:hover {
        background-color: $color-bondi-blue;
      }

      svg {
        color: rgba(0, 0, 0, 0.25);
      }
    }

    &-icon {
      margin-left: 10px;
      width: 20px;
      height: 20px;
      fill: $color-gray;
    }
  }

  &__actions-menu {
    display: flex;

    & .chat-input__btn {
      width: 18px;
      height: 18px;
      cursor: pointer;
    }

    & .chat-input__btn-icon {
      width: 16px;
      height: 16px;
    }
  }

  &__attach-btn,
  &__del-btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    font-size: 18px;
    color: #404040;

    &::after {
      content: '';
      position: absolute;
      top: -5px;
      left: -5px;
      right: -5px;
      bottom: -5px;
    }

    &:hover {
      svg {
        opacity: 1;
      }
    }

    > svg {
      opacity: .6;
      transition: opacity .15s cubic-bezier(0.4, 0.0, 0.2, 1);
    }
  }

  &__attach-btn {
    margin-right: 15px;
    cursor: pointer;

    .mail-chat-item--some-pending & {
      cursor: default;
  
      > svg {
        opacity: .3;
      }
    }
  }

  &__del-btn {
    padding: 0;

    &:disabled {
      cursor: default;

      > svg {
        opacity: .3;
      }
    }
  }
}
