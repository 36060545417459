@import "assets/scss/variables.scss";

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__content {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: normal;
    color: $color-gray;
    min-width: 0;
    margin: 0;
  }

  &__control-box {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }

  &__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    letter-spacing: 0;
  }

  &__icon {
    margin-right: 6px;
    width: 14px;
    height: 14px;
    color: #404040;

    &--sectors {
      height: 14px;
    }
  }

  &__count {
    display: block;
    margin-left: 4px;
  }
}

.menu {
  color: $color-bondi-blue;
}
