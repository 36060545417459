@import 'assets/scss/variables';

.booking-tag {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-width: 1px;
  border-style: solid;
  border-radius: 6px;
  letter-spacing: 0.5px;
  font-size: 12px;
  text-transform: capitalize;
  gap: 3px;
  align-self: center;
  color: $color-black;

  &__label {
    display: block;
    max-height: 16px;
  }

  &__count {
    display: block;
    min-width: 15px;
    text-align: left;
  }
}
