@import 'assets/scss/variables';

.user-menu {
  display: flex;
  align-items: center;
  margin-right: 28px;
  position: relative;
  cursor: pointer;

  &__img-wrap {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    overflow: hidden;
  }

  &__info {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    max-width: 100px;
  }

  &__name {
    color: #808080;	
    font-family: Roboto;
    font-size: 14px;
  }

  &__clock {
    padding: 0;
    font-size: 12px;
    cursor: pointer;
    color: $color-bondi-blue;
    text-transform: uppercase;
    text-align: left;
    width: max-content;
  }

  &__city {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__drop-menu-icon {
    width: 13px;
    height: 13px;
    margin-left: 15px;
    color: $color-bondi-blue;

    &--rotated {
      transform: rotate(180deg);
    }
  }

  &__status {
    width: 7px;
    height: 7px;
    position: absolute;
    border-radius: 50%;
    top: 3px;
    left: -5px;
    background-color: $color-gray;
    z-index: 2;

    &--online {
      background-color: $color-caribian-green;
    }

    &--busy {
      background-color: $color-froly;
    }

    &--away {
      background-color: $color-neon-carrot;
    }
  }

  .popup-menu {
    min-width: 240px;
    border-bottom: 2px solid $color-bondi-blue;
  }
}
