@import 'assets/scss/variables';

.checkbox {
  $transition-duration: 0.05s;

  position: relative;
  display: inline-flex;
  cursor: pointer;

  &__input {
    position: relative;
    cursor: inherit;
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    z-index: 1;

    &:checked + .checkbox__indicator {
      &:before {
        width: calc(100% - 6px);
        height: calc(100% - 6px);
      }
    }

    &:focus-visible + .checkbox__indicator:after {
      box-shadow: $shadow-focus;
    }
  }

  &__indicator {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    cursor: pointer;

    &::after {
      content: "";
      width: inherit;
      height: inherit;
      border: solid 2px $color-border-primary;
      border-radius: 2px;
      transition: border-color linear $transition-duration;
    }

    &::before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
    }
  }

  &--rectangled {
    & .checkbox__indicator::before{
      border-radius: 2px;
      background-color: $color-bondi-blue;
      transition: all linear $transition-duration;
    }

    & .checkbox__input:checked + .checkbox__indicator::after {
      border-color: $color-bondi-blue;
    }

    & .checkbox__input:focus-visible + .checkbox__indicator::after {
      border-color: $color-bondi-blue;
    }
  }

  &--checked {
    & .checkbox__indicator::before{
      mask-image: url(../../assets/icons/check.svg);
      mask-size: 95%;
      background-color: $color-screaming-green;
    }

    & .checkbox__input:checked + .checkbox__indicator {
      &::after {
        border-color: $color-screaming-green;
      }
    }

    & .checkbox__input:focus-visible + .checkbox__indicator::after {
      border-color: $color-screaming-green;
    }
  }
}
