@import 'assets/scss/variables';

.booking-filters-list-new {
  display: flex;
  position: relative;
  flex-flow: row wrap;

  &__counter-box {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 34px;

    &--center {
      top: 50%;
      transform: translateY(-50%);
    }

    &--top {
      top: 0;
    }

    &--bottom {
      bottom: 0;
    }
  }

  &__counter {
    margin-right: 4px;
    font-size: 12px;
    letter-spacing: 0.5px;
  }

  &__clear-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 26px;
    cursor: pointer;
    background-color: $color-white;
    border: 1px solid $color-persimmon;
    border-radius: 50%;

    &-icon {
      color: $color-persimmon;
      width: 10px;
      height: 10px;
    }
  }

  // & .booking-status-filters-new {
  //   background-color: $color-mystic;
    
  //   .booking-status-filters-new__item--active {
  //     background-color: $color-white;
  //   }
  // }
}
