@import 'assets/scss/variables';

.tasks-and-reminders-item {
  background-color: $color-wild-sand;
  border-radius: 6px;
  margin: 0 15px;

  &--open {
    position: relative;
    margin-right: 0;
    background-color: $color-white;
    box-shadow: -6px 0 6px 0 $color-persian-green;
  }

  &--done {
    background-color: rgba($color-mystic, 0.8);
    ;
  }

  &:not(:last-child) {
    margin-bottom: 6px;
  }

  &__top-line {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 17px;
    height: 28px;
  }

  &__text-box {
    display: flex;
    color: $color-black;
    overflow: hidden;
  }

  &__bottom-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: right;
    padding: 0 13px;
    height: 32px;
  }

  &__text {
    display: block;
    color: $color-text-primary;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__date {
    display: flex;
    color: $color-gray;
    font-size: 12px;
    align-items: center;

    & svg {
      margin-right: 10px;
      height: 20px;
      width: 20px;
      color: $color-bondi-blue;
    }
  }

  &__break-line {
    width: 100%;
    height: 2px;
    border-radius: 6px;
    background-color: $color-gray;
    opacity: 10%;
  }

  &__title {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__operator {
    max-width: 90px;
    min-width: 70px;
    font-size: 12px;
    color: $color-azure-radiance;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__checkbox {
    width: 17px;
    height: 17px;
    margin-right: 8px;
    border: 2px solid $color-gray;
    border-radius: 4px;
    cursor: pointer;

    &--checked {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px;
      border: 1px solid $color-caribbean-green;
      border-radius: 50%;
      color: $color-caribbean-green;

      & svg {
        width: 12px;
        height: 12px;
      }
    }
  }

  &__contact {
    display: flex;
    align-items: center;
    width: 100%;

    & .lazy-load__container {
      min-width: 24px;
      width: 24px;
      height: 24px;
      margin-right: 5px;
      border-radius: 50%;
      overflow: hidden;
    }

    &-name {
      font-size: 12px;
      color: $color-dodger-blue;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__tooltip {
    display: flex;
    flex-direction: column;
    width: 369px;
    padding: 17px 13px 12px 30px;
    background-color: $color-white;
    font-size: 12px;
    color: $color-tundora;
    box-shadow: 2px 0 0 0 $color-bondi-blue-dark;
    border-radius: 6px;
    cursor: pointer;

    &-title-box {
      position: relative;
      display: flex;
      padding-top: 15px;
      margin-top: 12px;

      &:after {
        content: "";
        background-color: $color-mystic;
        display: block;
        height: 1px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &-title {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    &-description {
      max-height: 150px;
      overflow-y: auto;

      &--reminder {
        padding: 8px 25px;
        max-height: 150px;
        overflow-y: auto;
        border-radius: 6px;
        background-color: rgba(136, 214, 217, 0.8);

        & .pinned-msgs-attachments {
          grid-gap: 5px;
          grid-auto-columns: 36px;
          grid-template-columns: repeat(9, 36px);
          grid-template-rows: auto;
          height: 36px;
        }
      }

    }
  }

  &__icon-quote {
    height: 9px;
    width: 10px;
    margin-right: 5px;
    fill: $color-gray;
  }

  &__icon-bell {
    width: 20px;
    height: 18px;
    margin-right: 7px;
    fill: $color-persimmon;

    &--remind {
      position: absolute;
      left: 4px;
      width: 9px;
      height: 10px;
      fill: $color-persimmon;
    }
  }

  &__reminders-wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding-bottom: 15px;
    // margin-bottom: 12px;

    // &:after {
    //   content: "";
    //   background-color: $color-mystic;
    //   display: block;
    //   height: 1px;
    //   width: 100%;
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    // }
  }

  &__reminders-text {
    display: flex;
    align-items: center;
    margin-right: 30px;
    font-size: 12px;
    color: $color-tundora;

    &--date {
      font-weight: bold;
      font-style: italic;
    }
  }

  &__date-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    & #delete {
      width: 18px;
      height: 20px;
      fill: $color-persimmon;
      cursor: pointer;
    }

    & #edit {
      fill: $color-bondi-blue;
      cursor: pointer;
    }

    & #clock {
      width: 20px;
      height: 20px;
      fill: $color-bondi-blue;
      cursor: pointer;
    }

    & #webmaster {
      height: 20px;
      width: 20px;
      color: $color-bondi-blue;
    }
  }

  &__client {
    display: flex;
    width: 100%;
    padding: 0 5px 8px;
    font-size: 12px;
    background-color: $color-athens-gray;
    border: 1px solid $color-mystic;
    border-radius: 6px;

    &--reminder {
      border: none;
      background-color: $color-white;

      & .lazy-load__container {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        overflow: hidden;
      }
    }

    &-wrapper {
      position: relative;
      padding-top: 15px;
      margin-top: 12px;

      &:after {
        content: "";
        background-color: $color-mystic;
        display: block;
        height: 1px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    & .lazy-load__container {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      overflow: hidden;
    }

    &-text-content {
      display: flex;
      flex-direction: column;
      max-width: 210px;
      margin-left: 10px;
    }

    &-name {
      max-width: 200px;
      overflow: hidden;
      color: $color-dodger-blue;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-contact {
      margin-top: 10px;
      overflow: hidden;
      color: $color-gray;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__created-info {
    display: flex;
    flex-direction: column;
    color: $color-bali-hai;
    font-size: 12px;
    padding-top: 15px;
    margin-top: 12px;
    position: relative;

    &-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:not(:last-child) {
        margin-bottom: 5px;
      }

      & span {
        color: $color-azure-radiance;
      }
    }

    &:after {
      content: "";
      background-color: $color-mystic;
      display: block;
      height: 1px;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &-tooltip__link, &__link {
    color: $color-azure-radiance;
  }

  &-tooltip__text {
    word-wrap: anywhere;
  }
}
