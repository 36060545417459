@import 'assets/scss/variables';


.calendar-bookings-list {
  --group-header-height: 41px;
  --offset: 45px;

  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $color-white;

  &--calendar-timeline {
    align-self: flex-end;
    width: calc(100% + var(--offset));
  }

  &--modal-timeline {
		padding: 0 15px;
  }

  &__header {
    display: flex;
    flex-direction: row;
  }

  &__list-container {
    position: relative;
    height: 99%;
    width: 100%;
    left: -7px;
  }

  &__scrollbar {
    right: 7px;
    width: 4px;
  }

  &__date-header {
    display: flex;
    position: sticky;
    top: -1px;
    width: 100%;
    align-items: center;
    height: var(--group-header-height);
    font-size: 14px;
    color: $color-bali-hai;
    padding-left: var(--offset);
    background-color: $color-white;
  }

  &__time-filter {
    z-index: 3;
    position: absolute;
    top: 0;
    right: 20px;
    height: var(--group-header-height);
  }

  &__list {
    height: 100%;
  }

  &__spinner-wrap,
  &__no-items {
    position: absolute;
    width: fit-content;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
  }

  &__no-items {
    font-size: 14px;
    color: $color-bali-hai;
  }

  &__item {
    position: relative;
    display: flex;

    &:first-of-type>time {
      visibility: visible;
    }

    @for $i from 0 through 23 {
      @for $j from 0 through 23 {
        @if $i != $j {
          &[data-hourmarker='#{$i}']+&[data-hourmarker='#{$j}']>time {
            visibility: visible;
          }
        }
      }
    }

    &[datetime]:last-of-type {
      background-color: black;
    }

    &--displayed-with-markers {
      // margin-left: var(--offset);
    }
  }

  &__hour-marker {
    visibility: hidden;
    min-width: 42px;
    color: $color-bali-hai;
    font-size: 14px;
    margin-right: 10px;
    text-align: right;
  }

  .scrollbars-thumb {
    background-color: $color-mystic;
  }
}
