@import 'assets/scss/variables.scss';

.contact-notes-tab {
  padding: 11px 9px;

  &__text-wrapper {
    padding: 9px 7px 4px 9px;
    border-radius: 2px;
    color: $color-gray;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0;
    background-color: $color-white;
  }

  &__text-content {
    cursor: text;
  }

  &__input {
    width: 100%;
    padding: 0;
    border: none;
    outline: none;
    color: $color-gray;
    font-size: 12px;
    line-height: 16px;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0;
    resize: none;
    padding: 7px;
  }

  &__btn-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }

  &__btn-close {
    width: 15px;
    height: 14px;
    background-color: $color-froly;
    border-radius: 3px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &:disabled {
      opacity: 0.5;
    }
  }

  &__close-icon {
    color: $color-white;
    width: 6px;
  }

  .clipboard-btn {
    margin-right: 8px;
    &__icon {
      width: 15px;
      height: 15px;
      color: $color-bondi-blue;
    }
  }
}