@import 'assets/scss/variables';

.widget-btn {
  display: flex;
  z-index: 10;
  position: fixed;
  left: 90px;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 6px;
  background-color: $color-bermuda;

  &--top {
    top: 135px;
  }

  &--bottom {
    bottom: 35px;
  }

  &--notifications {
    z-index: 35;
    left: auto;
    right: 90px;
  }

  &__icon {
    width: 30px;
    height: 30px;
    color: $color-bondi-blue;
  }

  &__counter {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 3px;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    color: $color-white;
    font-size: 10px;
    font-weight: 500;

    &--contacts {
      top: 3px;
      background-color: $color-froly;
    }

    &--task {
      top: 3px;
      background-color: $color-froly;
    }

    &--webmaster {
      bottom: 3px;
			background-color: $color-blaze-orange;
    }
  }

  &__content-wrapper {
    z-index: 35;
  }
}
