@import 'assets/scss/variables.scss';

.calendar-header {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 84px;
  padding: 0 70px 0 0;
  background-color: $color-mystic;
  position: relative;
  z-index: 1;

  
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 37px;
    margin-left: 87px;
  }

  &__input {
    height: 40px;
    width: 200px;
  }
  
  &__link-list {
    display: flex;
    flex-direction: row;
    gap: 15px;
  }

  &__calendar, &__rest {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__navbar-btn-wrap {
    width: 49px;
    height: 84px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;
    padding-right: 5px;
    width: 54px;
    background-color: $color-mystic;

    & > svg {
      color: $color-bondi-blue;
      width: 30px;
      height: 30px;
    }
  }
  
  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 32px;
    text-decoration: none;
    border-radius: 6px;
    border: 1px solid $color-white;
    background-color: transparent;
    font-size: 12px;
    color: $color-black;
    transition: all 250ms;
    cursor: pointer;
    
    &--active {
      background-color: $color-white;
      color: $color-bondi-blue;
    }
  }

  &__today-btn {
    margin-left: 26px;
  }

  &__tooltip {
    z-index: 1;
  }

  &__new-booking-btn {
    height: 40px;
    width: 112px;
    padding: 10px;
    margin-left: 15px;
  }

  &__export-btn {
    height: 40px;
    padding: 10px;
    margin-left: 15px;
    border: 2px solid $color-bondi-blue;

    svg {
      margin-left: 10px;
    }
  }
}
