@import 'src/assets/scss/variables.scss';

.tri-state-switch {
  display: flex;
  overflow: hidden;
  position: relative;
  border-radius: 9px;
  transition: all 0.4s ease-in-out;
  background-color: $color-silver;
  height: 10px;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: $color-white;
    transition: .4s;
  }
  
  &--off {
    &::before{
      right: 14px;
      background-color: $color-froly;
    }
  }

  &--waiting { 
    &::before{
      right: 50%;
      transform: translate(50%, -50%);
    }
  }

  &--available {
    &::before {
      right: 2px;
      background-color: $color-spring-green;
    }
  }

  input {
    position: absolute;
    height: 0;
    width: 0;
    overflow: hidden;
  }

  label {
    padding: 5px 4px;
    transition: all 0.4s ease-in-out;
  }

  label:hover {
    cursor: pointer;
  }
}
