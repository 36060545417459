@import 'assets/scss/variables.scss';

.adr-book-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 26px 4px 0 10px;
  background-color: $color-mystic;

  &--centered {
    justify-content: center;
    align-items: center;
    color: $color-silver;
    font-size: 14px;
    text-align: center;
  }

  &__auto-sizer-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__no-results {
    width: 100%;
    max-width: 224px;
    margin: 0 auto;
    &-desc {
      margin-bottom: 0;
      color: $color-gray;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
    }
  }

  &__global-search-btn {
    width: 213px;
    padding: 3px 0;
    border-radius: 6px;
    color: $color-white;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1px;
    background-color: $color-bondi-blue;

    &-wrap {
      padding: 24px 0 15px;

      &--is-search-result {
        padding-top: 0;
        margin-top: -10px;
        display: flex;
        justify-content: center;
      }
    }

    &:hover {
      opacity: 0.8;
    }

    &--modifier {
      position: absolute;
      left: 20%;
      top: 50px;
      height: 42px;
      margin: 0px;
    }
  }

  &__list {
    height: 100%;
    padding: 26px 10px 0;
    background-color: $color-mystic;
  }
}