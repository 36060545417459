// Colors
$color-white: #fff;
$color-white-opacity: rgba(255, 255, 255, 0.5);
$color-black: #000;
$color-amber: #ffc107;
$color-bermuda: #88D6D9;
$color-bermuda-opacity: rgba(136, 214, 217, 0.3);
$color-bondi-blue: #00A6AD;
$color-bondi-blue-dark: #00A9AF;
$color-persian-green: #00949a;
$color-caribbean-green: #01DF85;
$color-japanese-laurel: #058421;
$color-eucalyptus: #28a745;
$color-mystic: #E6EBF1;
$color-hawkes-blue: #cce9fc;
$color-razzle-dazzle-rose: #FF41BF;
$color-wild-sand: #f6f6f6;
$color-geyser: #dae0e6;
$color-tundora: #404040;
$color-mine-shaft: #222;
$color-shark: #2e2e30;
$color-tundora-light: #4a4a4a;
$color-gray: #808080;
$color-gray-approx: #828282;
$color-mecury: #e5e5e5;
$color-punch: #dc3545;
$color-manatee: #8E8E93;
$color-screaming-green: #3ef18e;
$color-alto: #D8D8D8;
$color-gallery : #EEEEEE;
$color-persimmon: #FF5E57;
$color-athens-gray: #F6F6F8;
$color-jagged-ice: #c9e4e9;
$color-viking: #5ac0de;
$color-azure-radiance: #0092F2;
$color-dodger-blue: #3565FD;
$color-silver: #bfbfbf;
$color-selective-yellow: #fbbc06;
$color-bali-hai: #8C97B2;
$color-jade: #00B936;
$color-green-haze: #00A562;
$color-fun-green: #008F14;
$color-apple: #2BC741;
$color-jade: #03c275;
$color-caribian-green: #01df85;
$color-spring-green: #29F18E;
$color-aqua-deep: #005947;
$color-aqua-island: #a7d7db;
$color-japanese-laurel: #0E970C;
$color-mandy: #e75d68;
$color-froly: #F8737F;
$color-rose-bud: #f9a9b0;
$color-red: #F20C0C;
$color-radical-red: #ff4052;
$color-pomegranate: #f01e1e;
$color-red-orange: #FF3D2B;
$color-orange: #FFA500;
$color-dawn-pink: #f6e9ec;
$color-mint-talib: #C5E9F4;
$color-zanah: #DDEFDD;
$color-ripe-lemon: #F2CC0C;
$color-black-squeeze-light: #F6F9FC;
$color-black-squeeze: #E7F6F7;
$color-seance: #88259C;
$color-pink: #E064F1;
$color-melanie: #eccdec;
$color-persian-pink: #F771A8;
$color-neon-carrot: #FF8C37;
$color-iceberg: #CBEDEF;
$color-tahiti-gold: #E88204;
$color-blaze-orange: #FF6F01;
$color-lucky-point: #1D1F7A;
$color-white-smoke: #F5F5F5;
$color-misty-rose: #FDE3E5;
$color-carousel-pink: #FDE2ED;
$color-persian-pink: #F77FBE;
$color-crystal-blue: #5CB3FF;
$color-sunset-orange: #FD5E53;
$color-outer-space: #454545;
$color-persian-green: #00A4AB;
$color-malibu: #62aff8;
$color-silver-sand: #bfc0c0;
$color-floral-white: #f6f8fb;

// Config
$color-text-primary: $color-tundora;
$color-text-primary-light: $color-gray;
$color-text-primary-darkest: $color-black;

$color-border-primary: $color-silver;
$color-border-success: $color-jade;
$color-border-error: $color-froly;
$color-border-focus: $color-bondi-blue;

$color-dark-background-tag: $color-athens-gray;

$shadow-primary: 0 12px 12px 0 rgba(0, 0, 0, .12);
$shadow-focus: 0 0 0 1px rgba($color-border-focus, 0.3);

$header-height: 62px;

$send-modal-header-height: 93px;
$send-modal-main-container-height: 360px - $send-modal-header-height * 2;

$box-shadow-popups: 0 24px 24px 0 rgba(0, 0, 0, 0.06);
