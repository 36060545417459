@import 'assets/scss/variables.scss';

.feedback-add-btn {
  padding: 9px 22px;
  background-color: $color-bondi-blue;
  border-radius: 6px;

  font-weight: 700;
  font-size: 16px;
  letter-spacing: 1px;
  color: $color-white;
}
