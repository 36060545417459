@import 'assets/scss/variables';
@import 'assets/scss/functions';

.select {
  $block: &; // For increase specificity
  cursor: pointer;

  &--dark {
    & #{$block}__control {
      min-height: 24px;
      height: 24px;
      font-size: 12px;
      border-color: transparent;
      border-radius: 6px;
      background-color: $color-gray;
      box-shadow: none;
      overflow: hidden;
      cursor: pointer;
      transition: all 0.15s ease-in-out;
      
      &:hover {
        border-color: transparent;
        box-shadow: none;
      }

      &--menu-is-open {
        background-color: $color-bondi-blue;
        box-shadow: none;
      }
    }

    & #{$block}__indicator {
      padding: 0;
      color: $color-white;

      &:hover {
        color: $color-white;
      }

      & > svg {
        height: 14px;
        width: 14px;
      }
    }

    & #{$block}__single-value {
      background-color: inherit;
      color: $color-white;
      text-align: left;
      height: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & #{$block}__value-container  {
      background-color: inherit;
      padding: 0 2px;
      height: 14px;
      width: 44px;

      & input {
        padding: 0;
      }
    }
  }

  &--light {
    & #{$block}__control {
      border-radius: 6px;
      background-color: $color-white;
      border-color: transparent;
      box-shadow: none;
      font-size: 14px;
      letter-spacing: 1px;

      &:hover {
        border: 1px solid $color-bondi-blue;
        box-shadow: none;
      }

      &--menu-is-open #{$block}__indicator {
        color: $color-bondi-blue;
      }

      &--is-disabled {
        filter: brightness(0.6)
      }
    }

    & #{$block}__indicator {
      color: hsl(0, 0%, 20%);

      & > svg {
        height: 14px;
        width: 14px;
      }
    }

    & #{$block}__placeholder {
      text-align: left;
      text-transform: capitalize;
      color: hsl(0, 0%, 20%);

    }

  }

  &--dim {
    & #{$block}__control {
      background-color: $color-mystic;
      letter-spacing: 1px;
      color: $color-mystic;
    }

    & #{$block}__single-value {
      color: $color-gray;
    }
  }

  #{$block}__menu {
    isolation: isolate;
    z-index: 100;
    margin: 0;
    padding: 0;
    text-align: left;
    width: max-content;
    min-width: 100%;
    box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    border-bottom: 2px solid $color-bondi-blue-dark;
    overflow: hidden;

    &-list {
      max-height: 300px;
      position: relative;
      padding: 0;
    }
  }

  #{$block}__control {
    height: 100%;
  }

  #{$block}__control--menu-is-open {
    & #{$block}__indicator {
      transform: rotate(180deg);
    }
  }

  #{$block}__option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 15px;
    letter-spacing: 1px;
    font-size: 12px;
    background-color: $color-white;
    cursor: pointer;

    &:active {
      background-color: inherit;
    }

    &:hover {
      background-color: $color-bermuda !important;
    }

    &:nth-child(even) {
      background-color: $color-athens-gray;
    }

    &:last-child {
      border-radius: 0 0 6px 6px;
    }

    & svg {
      position: relative;
      right: 0;
      height: 10px;
      width: 10px;
    }

    &--is-focused {
      background-color: $color-bermuda !important;
    }

    &--is-selected {
      background-color: inherit !important;
      color: $color-bondi-blue;
    }

    &--special {
      position: sticky;
      top: 0;
    }
  }

  #{$block}__single-value {
    text-align: left;
  }
  

  #{$block}__option-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  #{$block}__value-container {
    overflow: visible;
  }
}
