@import "assets/scss/variables.scss";

.calls-info-section {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  background-color: $color-bermuda-opacity;
  padding: 0 20px;
  transform: translate(0); // it needs for  fixed drowdown menu
  overflow: hidden;
  &:last-child {
      padding-right: 110px;
    }

  & > :nth-child(4) {
    z-index: -1;
  }

  & .title__icon {
    height: fit-content;
    width: fit-content;
  }

  &__blink {
    position: absolute;
    top: 10px;
    left: -20px;
    padding: 15px;
  }

  &__clear-all {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1px;
    background-color: $color-white;
    border-radius: 6px;
    // margin-left: 65px;
    white-space: nowrap;
    padding: 5px 13px;
    display: flex;
    align-items: center;
    height: 20px;

    &:hover {
      color: $color-white;
      background-color: $color-bondi-blue;
    }
  }

  &__header {
    display: flex;
    margin: 9px 0;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    // padding-right: 5px;
    // width: 100%;
    // z-index: 2;

    // .title {
    //   white-space: nowrap;
    //   text-overflow: ellipsis;
    //   overflow: hidden;
    // }

    // .calls-info-section__clear-all {
    //   margin-left: auto;
    // }
  }

  // &__header + div {
  //   .calls-info-section__list {
  //     margin-top: 0;
  //   }
  // }

  &__btn {
    border: 1px solid $color-azure-radiance;
    outline: none;
    border-radius: 5px;
    align-self: center;
    padding: 2px 0;
    font-size: 11px;
    width: 65px;
    background: none;
    margin-left: 10px;
    cursor: pointer;

    &--open-call-items {
      position: absolute;
      right: 0px;
      top: 0;
      padding: 0 10px;
      margin-right: 20px;
      // min-width: 80px;
      border: none;
      outline: none;
      font-size: 12px;
      font-weight: bold;
      height: 20px;
      width: 90px;
      border-bottom-left-radius: 2px;
      background-color: $color-tundora;
      color: $color-white;
      cursor: pointer;
      user-select: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &--show{
        background-color: $color-bondi-blue
      }

      &:hover {
        color: $color-white;
        background-color: $color-silver;
      }

      & > svg {
        margin-left: 5px;
        fill: $color-white;
      }
    }
  }

  &__item {
    position: relative;
    transform: translate(0);
    min-width: 250px;
    max-width: 400px;
    margin-right: 50px;
  
  }
  &__img {
    position: absolute;
    top: 14px;
    left: 0;
    border-left: 4px solid $color-text-primary;
    border-right: 4px solid $color-text-primary;
    width: 10px;
    height: 12px;
  }
  &__title {
    font-size: 14px;
    font-weight: normal;
    padding: 6px 0;
    cursor: pointer;
  }
  &__list {
    margin: 0;
    // padding: 0 10px 4px 0;
    list-style: none;
  }
  // &__title-box {
  //   display: flex;
  //   align-items: center;
  // }
  &__icon-phone {
    width: 27px;
    height: 27px;
    fill: $color-border-focus;
  }
}

.operator {
  background-color: $color-white;
  border-radius: 5px;
  width: 170px;
  margin-bottom: 10px;
  border: 1px solid $color-border-success;
  padding: 3px 0 3px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &--busy {
    border: 1px solid $color-border-error;
  }
  &--assistance {
    border: 1px solid $color-ripe-lemon;
  }
  &--offline {
    border: 1px solid $color-border-primary;
  }

  &__status {
    font-size: 13px;
    margin: 0;
    color: $color-gray;
  }
  &__unread {
    position: absolute;
    right: 50px;
    top: 8px;
    display: inline-block;
    width: 6px;
    height: 6px;
    background: $color-red;
    border-radius: 50%;
  }
  &__btns {
    display: inline-flex;
  }
  &__btn {
    display: inline-flex;
    border: none;
    outline: none;
    cursor: pointer;
    vertical-align: bottom;
    padding: 0 5px;
    margin: 0;
    background: none;

    &:active {
      transform: translateY(2px);
    }
    &:disabled {
      cursor: not-allowed;
      transform: unset;
    }
  }
}

.thumb-vertical {
  position: relative;
  display: block;
  width: 100%;
  cursor: pointer;
  border-radius: inherit;
  border: 1px solid $color-silver;
  background-color: #bfbfbf;
  height: 30px;
  transform: translateY(0px);
}
.track-vertical {
  position: absolute;
  width: 6px;
  right: 0px;
  bottom: 2px;
  top: 2px;
  border-radius: 3px;
  // background: $color-silver;
}
// .chat-title {
//   display: flex;
//   align-items: center;
//   font-size: 14px;
//   font-weight: normal;
//   color: $color-text-primary-light;
//   min-width: 0;
//   margin: 0;

//   &-icon {
//     display: inline-flex;
//     // width: 15px;
//     // min-width: 15px;))

//     // & svg {
//     //   width: 15px;
//     // }
//   }
//   &__text {
//     margin: 0 3px 0 5px;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//   }
// }
