@import 'assets/scss/variables.scss';

.contact-links-tab {
  padding: 23px 11px;

  &__item-header {
    display: flex;
    align-items: center;
  }

  &__item-icon {
    color: $color-silver;
  }

  &__item {
    margin-bottom: 15px;

    &-btns {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
      align-items: center;

      .clipboard-btn {
        margin-right: 18px;
        &__icon--copy {
          width: 16px;
          height: 16px;
          color: $color-bondi-blue;
        }
      }
    }

    &-btn {
      &--delete {
        padding: 0;
        color: #F8737F;
      }
    }
  }

  &__msg-template-title {
    margin: 28px 9px;
    color: $color-gray;
    font-size: 14px;
    line-height: 24px;
    font-family: Roboto;
    letter-spacing: 0;
  }

  &__link-input {
    border: none;
    outline: none;
    background-color: $color-white;
    width: 100%;
    border-radius: 6px;
    padding: 12px 9px 6px;
    color: $color-gray;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 19px;
  }

  &__note {
    width: 100%;
    padding: 12px 9px 6px;
    border: none;
    border-radius: 6px;
    outline: none;
    color: $color-tundora-light;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0;
    resize: none;
    background-color: $color-white;
  }

  &__btn {
    &-add {
      display: flex;
      position: relative;
      align-items: center;
      color: $color-gray;

      &-icon {
        width: 20px;
        height: 20px;
        color: $color-bondi-blue;
      }
      
      &::after {
        display: block;
        position: absolute;
        left: 27px;
        width: 110px;
        padding-left: 5px;
        font-size: 14px;
        text-align: left;
        content: "Add Link";
      }
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}