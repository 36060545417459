@import 'assets/scss/variables.scss';
@import 'assets/scss/functions.scss';

.search-list {
  display: flex;
  flex-direction: column;

  @include ModalWindow(
    690px,
    370px,
    column,
    (
      header: (
        padding: 32px 20px 24px,
      ),
      main-container: (
        padding: 12px 14px 24px,
      )
    ),
    '--modal'
  );

  &--empty {
    height: 100%;
    width: 100%;
  }
  
  &__header {
    flex-direction: column;
    flex-shrink: 0;
  }

  &__input-label {
    margin-bottom: 6px;
    display: inline-block;

    font-size: 12px;
    letter-spacing: 1px;
  }

  &__main-container {
    flex-grow: 1;

    &--centered {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .track-vertical {
      bottom: 10px;
      width: 5px;
    }
    
    .thumb-vertical {
      border: none;
      background-color: $color-gray;
    }
  }

  &__no-items {
    font-size: 16px;
    color: $color-gray;
  }

  &__infinite-scroll {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &::-webkit-scrollbar{
      width: 0;
  }
  }

  &__bottom-spinner {
    margin: 0 auto;
    display: block;
  }

  &.modal__window {
    width: 370px;
    height: 690px;
  }

  &__header.modal__window-header {
    padding: 28px 20px 22px;
  }

  &__main-container.modal__window-main-container {
    padding: 12px 14px 24px;
  }

  &__item {
    overflow: hidden;
    cursor: pointer;
    margin-right: 1px;
    
    &--multiple:hover {
      position: relative;
      
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 12px;
        width: 16px;
        height: 16px;
        mask-image: url('../../assets/icons/plus-circle.svg');
        background-color: $color-bondi-blue;
        pointer-events: none;
      }
    }
  }

  &__extra-container {
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 16px;
    margin-bottom: 16px;
    color: $color-black;
    letter-spacing: 0.88px;

    &::after {
      content: '';
      position: absolute;
      width: 200px;
      height: 2px;
      bottom: 0;
      background-color: $color-silver-sand;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__extra-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 22px;
    height: 47px;
    background-color: $color-black-squeeze-light;
    border-radius: 6px;
    user-select: none;
    cursor: pointer;
  }
}
