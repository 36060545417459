@import 'assets/scss/variables';

.header-dropdown-btns {
  display: flex;
  align-items: center;

  &__button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-right: 15px;
    padding: 0;
    width: 48px;
    height: 48px;

    &:last-child {
      margin-right: 0;
    }

    &--voicemails {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &--open {
      background-color: $color-bermuda;
      border-radius: 6px;
    }
  }

  &__icon {
    color: $color-bondi-blue;
    width: 20px;
    height: 20px;

    .header-dropdown-btns__button--open & {
      color: #ffffff;
    }
  }
}