@import '../../assets/scss/variables.scss';

.adr-book-header {
  display: flex;
  align-items: center;
  min-height: 84px;
  padding: 0 20px 0 0;
  background-color: $color-mystic;
  position: relative;
  z-index: 1;

  &__adr-book-btn {
    height: 36px;

    & svg {
      width: 12px;
      height: 12px;
      margin-left: 8px;
    }

    &--delete svg {
      width: 17px;
      height: 17px;
    }
  }

  &__undo {
    &-block {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      margin-right: 30px;
      padding: 15px;
      border-radius: 6px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.88px;
      background-color: $color-black-squeeze-light;

      &-saved-msg {
        padding: 11px 0;
      }
    }

    &-spinner-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $color-black-squeeze-light;
      padding: 6px;
    }

    &-text {
      font-size: 12px;
      text-align: start;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      & span {
        font-size: 14px;
        letter-spacing: 0.88px;
        line-height: 20px;
        color: $color-bondi-blue;
      }
    }

    &-btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-grow: 1;
    }

    &-btn-icon {
      min-width: 20px;
      width: 20px;
      height: 20px;
      color: $color-bondi-blue;
    }
  }

  &__select-spinner {
    width: 183px;
    margin-left: 8px;
  }

  .react-select {
    $block: '.react-select';
    margin-left: 8px;
    font-size: 14px;

    #{$block}__control {
      width: 183px;
      padding-left: 10px;
      cursor: pointer;
    }

    #{$block}__single-value {
      font-weight: normal;
    }

    #{$block}__indicator-separator {
      display: none;
    }

    #{$block}__option {
      font-size: 12px;
    }

    #{$block}__menu,
    #{$block}__menu-list {
      border-radius: 0 0 6px 6px;
    }
  }

  &__mailbox-users {
    display: flex;
    margin-left: 20px;

    &-wrapper {
      display: flex;
      align-items: center;
      color: $color-gray;
      font-size: 14px;
      text-align: center;
    }

    .lazy-load__container {
      width: 32px;
      height: 32px;
      overflow: hidden;
      margin-left: 10px;
    }
  }

  &__actions {
    display: flex;
    flex-grow: 1;
    margin-left: 40px;
    gap: 10px;
  }

  &__icon {
    min-width: 23px;
    width: 23px;
    height: 26px;
    color: $color-silver;
  }

  &__dropdown {
    color: $color-gray;
    font-size: 14px;
    line-height: 19px;
    font-family: Roboto;
    letter-spacing: 0;
  }

  &>.search-input {
    margin-left: 70px;
  }

  &__search-input {
    width: 400px;
    padding: 10px 25px 10px 10px;
    border: solid 1px $color-bermuda;
    border-radius: 6px;
    outline: none;
    color: $color-black;
    height: 36px;
    font-size: 14px;
    font-family: Roboto;
    letter-spacing: 0.88px;
    background-color: $color-athens-gray;

    &::placeholder {
      color: $color-silver;
    }
  }

  &__navbar-btn-wrap {
    padding-right: 5px;
    width: 54px;
    height: 84px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;
    background-color: $color-mystic;

    & > svg {
      color: $color-bondi-blue;
      width: 30px;
      height: 30px;
    }
  }
}

.adr-book-header-select {
  position: relative;
  width: 100%;
  max-width: 172px;
  margin-left: 15px;
  color: $color-gray;
  font-size: 14px;
  cursor: pointer;

  &__input {
    opacity: 0;
    position: relative;
    left: -100px;
    width: 1px;
    padding: 0;
    border: 0;
    outline: 0;
    color: transparent;
    font-size: inherit;
    background: 0;
    transform: scale(0);
  }

  &__list {
    z-index: 2;
    position: absolute;
    top: 25px;
    width: 100%;
    margin: 0;
    padding: 0;
    border: 1px solid $color-white;
    list-style: none;
    background-color: $color-white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.12);
  }

  &__option {
    width: 100%;
    padding: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &--active {
      color: $color-bermuda;
    }

    &--focused {
      color: $color-black;
      cursor: pointer;
      background-color: $color-wild-sand;
    }
  }

  &__input-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--focused {
      .adr-book-header-select__indicator {
        color: $color-bermuda;
      }
    }
  }

  &__indicator {
    width: 13px;
    height: 9px;
  }
}
