@import 'assets/scss/variables';

.booking-filters {
  $this: &;

  display: flex;
  position: relative;
  align-items: center;

  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    height: 36px;
    width: 36px;

    // padding: 6px;
    background-color: $color-white;
    border: 1px solid $color-mystic;

    &:disabled {
      cursor: not-allowed;
    }

    &>svg {
      width: 66%;
      height: 62%;
    }

    &::after {
      width: 22px;
      height: 22px;
      mask-size: 100%;
    }

    &--active {
      background-color: rgba(#88D6D9, 0.44);
    }

    &--currentgirl {
      height: 34px;
      width: 33px;
      border-radius: 50%;
      box-shadow: 0px 0 0px 1px $color-white;

      &#{$this}__item--active {
        box-shadow: 0px 0 0px 1px rgba(#88D6D9, 0.44);
      }
    }
  }
}
