@import "assets/scss/variables.scss";

.contact-bookings {
  &__header {
    display: flex;
    flex-direction: column;
    padding: 6px 15px 12px;
    border-bottom: 1px solid $color-gray;
  }

  &__filters-list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  &__booking-tag {
    height: 32px;
    padding: 0 8px;
    border-color: $color-white;

    &--active {
      background-color: $color-white;
      color: $color-bondi-blue;
    }

    &:disabled {
      color: inherit;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__title {
    font-size: 12px;
    color: $color-bali-hai;
    text-align: left;

    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }
  
  &__subtitle {
    text-transform: capitalize;
    color: $color-black;
  }

  &__list-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 12px;
  }

  &__no-items {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #bfbfbf;
    font-size: 14px;
  }

  &__list-container {
    height: 100%;
  }

  &__list {
    display: flex;
    flex-direction: column;

    &:has(.booking-client-item) {
			gap: 12px;
    }

    &:has(.booking-girl-item) {
      gap: 6px;
    }
  }
}
