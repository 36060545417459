@import 'assets/scss/variables';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: $header-height;

  &__item {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;

    &--active-call {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 455px;
      margin: 0;
      padding-left: 0;
    }

    &--user-menu {
      width: 390px;
    }
  }
}
