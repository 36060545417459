@import 'assets/scss/variables';

.tasks-and-reminders {
  display: flex;
  flex-direction: column;
  width: 490px;
  height: 70vh;
  border-radius: 6px;
  font-size: 12px;
  background-color: $color-bermuda;
  border-bottom: 2px solid $color-bondi-blue;
  box-shadow: 0 24px 24px 0 rgba(0, 0, 0, 0.06);

  &__header {
    display: flex;
    align-items: center;
    height: 70px;
    padding: 15px 15px 15px 10px;
    background-color: $color-mystic;
  }

  &__filters-box {
    display: flex;
    margin-right: 15px;
  }

  &__icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background-color: $color-white;
    border-radius: 6px;
    border: 1px solid $color-black-squeeze;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  &__icon-user {
    width: 18px;
    height: 20px;
    color: $color-gray;

    &--active {
      color: $color-bondi-blue;
    }
  }

  &__icon-female {
    width: 10px;
    height: 20px;
    color: $color-gray;

    &--active {
      color: $color-bondi-blue;
    }
  }

  &__search {
    margin-right: 15px;
  }

  &__search-wrap svg {
    width: 10px;
    height: 10px;
  }

  &__search-input {
    height: 38px;
    width: 215px;
  }

  &__btn-wrap {
    min-width: 110px;
    margin-right: 19px;
    padding: 8px 7px;
    background-color: $color-white;
    border-radius: 6px;
    text-align: center;
  }

  &__btn {
    font-size: 12px;
    color: $color-bondi-blue;
  }

  &__icon-plus {
    color: $color-bondi-blue;
    cursor: pointer;
  }

  &__list-container {
    width: 100%;
    height: 100%;
  }

  &__bookings-list-wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 2px;

    .scrollbars-thumb {
      background-color: $color-mystic;
    }
  }

  // &__bookings-list-title {
  //   font-size: 14px;
  //   padding-left: 50px;
  //   color: $color-bali-hai;
  //   margin-bottom: 13px;
  // }

  &__spinner-wrap,
  &__no-items {
    position: absolute;
    width: fit-content;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
  }

  &__no-items {
    font-size: 14px;
    color: $color-bali-hai;
  }
}
