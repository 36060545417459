@import 'assets/scss/variables';

[data-isanimate="true"] {
  animation: shakeMe 1s linear;
}

@keyframes shakeMe {

  0%,
  20%,
  40%,
  60%,
  80% {
    transform: translateX(-1px);
  }

  30%,
  50%,
  70%,
  10%,
  90% {
    transform: translateX(1px);
  }

  100% {
    transform: translateX(0px);
  }
}
