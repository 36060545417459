@import 'assets/scss/variables';

.tooltip {
  --tooltip-border-radius: 6px;

  position: relative;
  box-shadow: $shadow-primary;
  background-color: $color-white;
  overflow-wrap: anywhere;
  color: $color-gray;
  border-radius: var(--tooltip-border-radius);
  font-size: 12px;
  letter-spacing: 1px;
  padding: 10px;
  text-align: center;
  transform: translateY(-5px);
  z-index: 120;
  opacity: 0;

  &--dark {
    background-color: $color-mystic;
  }

  &--semidark {
    color: $color-silver-sand;
    background-color: $color-black-squeeze-light;
  }

  &--main {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: $shadow-primary;
      border-radius: var(--tooltip-border-radius);
    }
  }

  &--top {
    --arrow-position: 'bottom'
  }

  &--bottom {
    --arrow-position: 'top'
  }

  &--left {
    --arrow-position: 'right'
  }

  &--right {
    --arrow-position: 'left'
  }

  &__arrow {
    position: absolute;
    z-index: 2;
    width: 5px;
    height: 5px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 8px;
      height: 8px;
      background: inherit;
      background-color: #fff;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }


    &--dark::before {
      background-color: $color-mystic;
    }

    &--semidark::before {
      background-color: $color-athens-gray;
    }
  }

  @container style(--arrow-position: 'bottom') {
    &::before {
      box-shadow: 0 2px 0 0 $color-bondi-blue;
    }

    &__arrow {
      bottom: 0;

      &--main::before {
        box-shadow: 2px 2px 0 0 $color-bondi-blue;
      }
    }
  }

  @container style(--arrow-position: 'top') {
    &::before {
      box-shadow: 0 -2px 0 0 $color-bondi-blue;
    }

    &__arrow {
      top: -1px;

      &--main::before {
        box-shadow: -2px -2px 0 0 $color-bondi-blue;
      }
    }

  }

  &-enter-done {
    opacity: 1;
    transition: opacity 0.2s;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    transition: opacity 0.2s;
  }
}
