@import 'assets/scss/variables';

.navbar {
  position: relative;
  border-radius: 6px;
  background-color: $color-mystic;
  transition: all linear 0.1s;

  &--active {
    background-color: $color-white;
    box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.12);
  }

  &__list {
    display: flex;
    border-radius: 6px;
    background-color: $color-mystic;

    &--column {
      flex-direction: column;
      position: absolute;
      background-color: $color-white;
      z-index: -1;
      left: 0;
      top: 65px;
      opacity: 0;
      transform: translateY(-100%);
      transition: all ease-out 0.15s;
      width: 50px;
      border-radius: 0 0 6px 6px;
      box-shadow: none;
    }

    &--active {
      opacity: 1;
      transform: translateY(19px);
      box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.12);
    }
  }

  &__item {
    overflow: hidden;
    width: 50px;
    height: 50px;
   
    &:first-child {
      border-radius: 6px 0 0 6px;
    }
    &:last-child {
      border-radius: 0 6px 6px 0;
    }

    &--disabled {
      pointer-events: none;

      .navbar__link {
        color: $color-gray;
      }
    }

    &--active {
      .navbar__link {
        color: $color-white;
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: $color-tundora;
    border-radius: 6px;
    cursor: pointer;

    &--active {
      background-color: $color-bermuda;
      color: $color-white;
    }

    &--active.navbar__link--is-burger-menu {
      color: $color-bondi-blue;
    }
  }

  &__btn {
    width: 48px;
    height: 18px;
    background-color: $color-bermuda;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    width: 20px;
    height: 20px;
    &--arrow {
      color: $color-white;
      width: 15px;
      height: 15px;
      transition: all linear 0.1s;
    }
    &--open {
      transform: rotateX(180deg);
      transition: all ease-out 0.15s;
    }
  }
}