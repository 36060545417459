@import 'assets/scss/variables';

.booking-client {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  text-align: left;

  &__avatar {
    height: 36px;
    width: 36px;
    border-radius: 50%;
  }

  &__name {
    width: fit-content;
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
    color: $color-dodger-blue;
    max-height: inherit;
    cursor: pointer;
  }
}
