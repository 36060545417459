@import '../../assets/scss/variables.scss';

.adr-book-list-wrapper {
  display: flex;
  flex-direction: column;
  width: 390px;
  min-width: 390px;
  height: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 36px;
    padding: 8px 0;
    color: $color-gray;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1px;

    &--escort {
      justify-content: space-between;
    }
  }

  &__select-btn {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    gap: 6px;
    color: $color-tundora;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    font-weight: 500;

    &--selected {
      font-weight: 500;
      padding: 3px 6px;
      border-radius: 6px;
      background-color: $color-bermuda;
    }

    & svg {
      width: 12px;
      color:$color-bondi-blue;
    }
  }

  &__search-count {
    font-weight: initial;
  }

  &__list {
    height: 100%;
    padding: 26px 10px 0;
    background-color: $color-mystic;
  }

  &__show-ex {
    display: flex;
  }

  &__checkbox-btn {
    display: flex;
    align-items: center;
  }

  &__checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    margin-right: 7px;
    background-color: white;
    border: 2px solid $color-silver;
    border-radius: 4px;

    &--active {
      background-color: $color-bondi-blue;
      border-color: $color-bondi-blue;
    }

  }

  &__show-ex-icon {
    color: $color-white;
  }

  &__show-ex-text {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: $color-gray;
  }
}
