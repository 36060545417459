@import 'assets/scss/variables';

.tags-field {
  $block: &;

  &-container #{$block}__tag {
    background-color: $color-dark-background-tag;
  }

  &-container {
    width: 100%;
    border: 1px solid $color-mystic;
    border-radius: 6px;
  }

  &-container #{$block}__control {
    border: none;
    border-radius: 6px;
    min-height: 94px;
    align-items: flex-start;
    transition: all 0.15s ease-in-out;

    &:focus-within {
      box-shadow: 0 0 0 1px $color-border-focus;
    }

    &--is-disabled {
      background-color: $color-white;

    }
  }

  &-container #{$block}__menu {
    font-size: 11px;
    border-radius: 6px;
    z-index: 10;

    & > div {
      scrollbar-width: none;
    }
  }

  &-container #{$block}__placeholder {
    font-size: 14px;
    margin-top: 3px;
  }

  &-container #{$block}__input {
    font-size: 14px;
    margin: 0;
  }

  &-container #{$block}__value-container {
    padding: 6px 8px;
    gap: 5px;
    align-items: flex-start;
    overflow: visible;
  }
}
