@import 'assets/scss/variables.scss';

.operators-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100% - 38px);
  margin: auto;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    
    &-icon {
      margin-right: 6px;
      width: 15px;
      height: 15px;
      color: $color-gray;
      transform: translateY(-1px);
    }
  }

  &__title {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: $color-gray;
  }

  &__list-container {
    flex-grow: 1;
    margin-right: -10px;
    margin-bottom: 20px;
    margin-top: 6px;
  }
  &__list {
    padding-right: 10px;
  }
  &__no-items {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #808080;
  }
}

.operators-list-item {
  position: relative;
  display: flex;
  // width: calc(100% - 13px) !important;
  height: 40px !important;
  border: 1px solid $color-athens-gray;
  background-color: $color-athens-gray;
  border-radius: 6px;
  padding: 3px 7px 3px 5px;
  // margin-left: 4px;
  outline: none;
  transition-property: border-color, box-shadow, height;
  transition-duration: 0.1s;
  transition-timing-function: ease-in;
  cursor: pointer;
  
  &:not(:last-child) {
    margin-bottom: 9px;
  }

  .menu {
    padding: 5px 0 5px 8px;
  }
  
  &__tooltip{
    margin-left: -50px;
  }

  // &:hover {
  //   border-color: transparent;
  //   box-shadow: 0 3px 6px 0 rgba(0,0,0,0.12);
  //   cursor: pointer;
  // }
  
  &--pending {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #cacaca;
  }

  &--active {
    border: 1px solid $color-bondi-blue;
    background-color: rgba(136, 214, 217, 0.44);
  }

  &::after {
    box-sizing: border-box;
    display: inline-block;
    width: 7px;
    height: 7px;
    position: absolute;
    border-radius: 50%;
    top: 2px;
    left: 1px;
    transition: all 0.2s ease-in;
  }

  &__menu-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 81px;
  }

  &__mode {
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    color: $color-green-haze;
    
    &--off {
      color: $color-red;
    }

    &--sms {
      color: $color-neon-carrot;
    }

    &--phone {
      color: $color-dodger-blue;
    }
  }

  &__img-wrap {
    width: 32px;
    height: 32px;
    position: relative;
    margin-right: 5px;
    min-width: 32px;
  }

  &__status {
    width: 7px;
    height: 7px;
    position: absolute;
    border-radius: 50%;
    top: -3px;
    left: -5px;
    background-color: $color-gray;
    z-index: 2;

    &::after {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: calc(100% - 14px);
      top: -6px;
      z-index: 9999;
    }

    &--online {
      background-color: $color-caribian-green;
    }

    &--busy {
      background-color: $color-froly;
    }

    &--away {
      background-color: $color-neon-carrot;
    }
  }

  &__img {
    border-radius: 50%;
  }

  &__content-wrap {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    min-width: 0;
  }

  &__content {
    min-width: 0;
    color: $color-gray;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 12px;
    flex-grow: 1;
  }

  &__info {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
  }

  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
    text-transform: capitalize;

    &-wrap {
      display: flex;
      flex-grow: 1;
      align-items: center;
      margin-right: 5px;
      overflow: hidden;
    }
  }

  &__tel {
    max-width: 135px;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__sessions-indicator {
    display: flex;
    font-size: 12px;
    font-style: italic;

    span {
      padding-top: 2px;
      padding-left: 2px;
      color: $color-black;
    }
  }

  &__puzzle-icon {
    width: 14px;
    height: 14px;
    fill: $color-silver; 
  }
}

.operators-list-item__menu-wrapper .icon-menu {
  padding: 5px 0 5px 8px;
  & svg {
    color: $color-gray;
    width: 14px;
  }
}
