@import 'assets/scss/variables';

.search-input {
  position: relative;

  &:focus-within {
    .search-input__btn {
      color: $color-border-focus;
    }
  }

  &__input {
    border-radius: 6px;
    padding: 0 40px 0 10px;
    line-height: 1;
    letter-spacing: 1px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid;
    transition: all 0.15s ease-in-out;
    outline: none;

    &:focus {
      border-color: $color-border-focus;
    }

    &--primary {
      border-color: #bfbfbf;
      color: #404040;
      background-color: #fff;

      &::placeholder {
        color: black;
      }

      &--error {
        border: 1px solid $color-border-error !important;
      }
    }

    &--inversion {
      background-color: $color-bermuda;
      border-color: $color-bermuda;
    }

    &--dim {
      background-color: $color-athens-gray;
      border: 1px solid $color-athens-gray;

      &::placeholder {
        color: $color-silver;
      }
    }

    &--dim-width-border {
      background-color: $color-athens-gray;
      border: 1px solid $color-bermuda;

      &::placeholder {
        color: $color-silver;
      }
    }
  }

  &__btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
    width: 20px;
    height: 20px;
    color: $color-silver;
    transition: color 0.15s ease-in-out;

    &--clear {
      cursor: pointer;
    }
  }

  &__icon {
    color: $color-bondi-blue;
    
    &--big {
      width: 20px;
      height: 20px;
    }

    &--medium {
      width: 16px;
      height: 16px;
    }

    &--small {
      width: 12px;
      height: 12px;
    }

    &--clear {
      width: 12px;
      height: 12px;
      color: $color-red-orange;
    }
  }

  &__button-container {
    display: flex;
    align-items: center;
    position: absolute;
    gap: 4px;
    right: 12px;
    top: 0;
    height: 100%;
  }
}
