@import 'assets/scss/variables';

.booking-status1 {
  text-transform: capitalize;
  color: $color-azure-radiance;

  &--finished {
    color: $color-japanese-laurel;
  }

  &--in-progress {
    color: $color-blaze-orange;
  }

  &--cancelled {
    color: $color-red;
  }
}
