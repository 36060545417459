@import '../../assets/scss/variables.scss';

.adr-book-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  
  &__selected-list {
    margin: 16px 20px;
  }
  
  &__selected-list-wrap {
    width: 100%;
    
    &-title {
      color: $color-gray;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 24px;
    }
  }
  
  &__main {
    display: flex;
    gap: 30px;
    height: calc(100% - 84px);
    padding: 16px 30px 5px 20px;
  }
}
