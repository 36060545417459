@import 'assets/scss/variables.scss';

.date-time-changer {
  display: flex;
  align-items: center;

  &__wrapper {
    position: relative;
  }

  &__container {
    position: relative;
    display: flex;
    align-items: center;
    width: 218px;
    height: 32px;
    margin: 0 12px;
    padding: 7px 12px;
    border-radius: 6px;
    font-size: 12px;
    color: $color-bali-hai;
    background-color: $color-white;
    border: 1px solid $color-bondi-blue;
    cursor: pointer;
  }

  &__calendar-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    color: $color-bondi-blue;
  }

  &__prev-btn, &__next-btn {
    display: flex;
  }

  &__prev-btn {
    padding-right: 0;
  }

  &__next-btn {
    padding-left: 0;
  }

  &__prev-icon, &__next-icon {
    width: 12px;
    height: 12px;
    color: $color-bondi-blue;
    cursor: pointer;
  }

  &__next-icon {
    transform: rotate(180deg);
  }

  &__tooltip {
    z-index: 4;
  }
}
