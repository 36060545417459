@import 'assets/scss/variables';

.booking-markers {
  display: flex;
  flex-direction: row;
  gap: 8px;

  &__item {
    height: 14px;
    width: 14px;

    &--prebooking {
      mask-image: url(../../../../assets/icons/p.svg);
      mask-size: 14px;
      background-color: $color-persian-pink;
    }

    &--booking {
      mask-image: url(../../../../assets/icons/b.svg);
      mask-size: 14px;
      background-color: $color-froly;
    }

    &--notes {
      mask-image: url(../../../../assets/icons/warn.svg);
      mask-size: 14px;
      background-color: $color-red;
    }

    &--requests {
      mask-image: url(../../../../assets/icons/r.svg);
      mask-size: 14px;
      background-color: $color-azure-radiance;
      scale: 1.13
    }

    &--agent {
      mask-image: url(../../../../assets/icons/a.svg);
      mask-size: 14px;
      background-color: $color-seance;
    }
  }
}
